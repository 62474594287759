<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Edit Building">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="12">
                                        
                                        <b-form-group label="Project Site" class="required">
                                            
                                            <v-select
                                                v-model="form.site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                :clearable="false"
                                            >
                                              <template #selected-option="{ site_name }">
                                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                                              </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                        <b-form-group
                                          label="Building Name"
                                          class="required"
                                        >
                                          <b-form-input
                                            placeholder="3 to 100 characters"
                                            v-model="form.name"
                                          />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                        <b-form-group
                                          label="No. of Levels"
                                          class="required"
                                        >
                                          <b-form-input
                                            placeholder=""
                                            v-model="form.no_of_levels"
                                          />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                      <b-form-checkbox
                                        v-model="form.basement"
                                        value="yes"
                                        unchecked-value="no"
                                        class="custom-control-warning"
                                        @change="form.no_of_basements = ''"
                                        
                                      >
                                        Add Basement
                                      </b-form-checkbox>
                                      
                                  </b-col>
                                </b-row>

                                <b-row class="mt-2" v-if="form.basement == 'yes'">
                                    <b-col md="12">
                                        <b-form-group
                                          label="No. of Basement"
                                          class="required"
                                        >
                                          <b-form-input
                                            placeholder=""
                                            v-model="form.no_of_basements"
                                          />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
          
                                <b-row class="mt-2">
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'hand-sanitizer-buildings' })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                sites:[],
                form :{
                    name : '',
                    site : null,
                    no_of_levels:'',
                    id   : '',
                    basement:'no',
                    no_of_basements:''
                },              
            }
        },

        methods : {
            formSubmit(){

                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : '/api/update-hand-sanitizer-buildings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'hand-sanitizer-buildings' })
                        });
                    }
                });
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },
            getDetail(){
                return this.$store.dispatch(POST_API, {
                    data: {
                        id : this.$route.params.id
                    },
                    api: '/api/hand-sanitizer-building-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        this.form.name = data.name;
                        this.form.site = data.site;
                        this.form.no_of_levels = data.no_of_levels;
                        this.form.id   = data._id;
                        this.form.basement = data.basement;
                        this.form.no_of_basements = data.basement == 'yes' ? data.no_of_basements : '';
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'custom-form-settings'},
                text:'Form Settings',
              },
              {
                to: {name:'hand-sanitizer-buildings'}, // hyperlink
                text: 'Buildings', // crumb text
              },{
                to: null, // hyperlink
                text: 'Edit Building', // crumb text
                active:true
              }];
              return item;
            }
        },
        mounted(){
            this.allSites();
            this.getDetail();
        }
    }
</script>
